@import '../colors.css';

.carousel-loading-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.hidden {
    display: none;
}

.image-carousel {
    position: relative;
    transition: transform 0.4s ease;
    max-width: fit-content;
    min-width: 300px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-image {
    max-width: 380px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin-top: 16px;
    display: block;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 3px;
    padding-bottom: 5.5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease-in-out;
    width: 20px;
    /* Ensure width and height are the same */
    height: 22px;
}

.carousel-button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.carousel-button.left {
    left: 2px;
    padding-right: 4px;
}

.carousel-button.right {
    right: 2px;
    padding-left: 4px;
}

.carousel-counter {
    position: absolute;
    bottom: 7px;
    right: 7px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

@media (max-width: 576px) {
    .image-carousel:hover {
        transform: none;
    }

    .carousel-button:hover {
        background: rgba(0, 0, 0, 0.5);
    }

}