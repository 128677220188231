@import '../colors.css';

.home {
  padding: 2rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  /* Ensures the layout covers the full height */
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  gap: 10px;
}

/* Fullscreen overlay */
.uploader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  /* Ensure it appears above other elements */
  color: white !important;
  font-size: 1.2rem;
  font-weight: bold;
}

.uploader-overlay p {
  color: white !important;
}

/* Spinner animation */
.uploader-spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  top:57%;
  left: 50%;
  border: 6px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ccc;
  border-top-color: #3498db;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.intro-box {
  background-color: var(--primary-color);
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.workplace-filters-container {
  background-color: var(--primary-color);
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.workplace-filters-container h2 {
  font-size: 24px;
  color: var(--dark-green);
  margin-bottom: 2px;
  font-family: 'Lora', serif;
}

.apply-filters-button {
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  background-color: #333333f4;
  color: #fff;
  transition: background-color 0.3s ease;
}

.apply-filters-button:hover {
  background-color: #303030ca;
  /* Darker green */
}

.clear-filters-button {
  top: 10px;
  right: 10px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  transition: background-color 0.3s ease;
}

.clear-filters-button:hover {
  background-color: #c9302c;
}

.filters-box {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
}

.filters-group {
  margin-right: 30px;
}

/* Labels */
.filters-group label {
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.5rem;
  color: var(--dark-green);
}

/* Input fields */
.filters-group input,
.filters-group select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.content-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-grow: 1;
  /* Make it stretch to fill remaining space */
}

.intro-box h2 {
  font-size: 24px;
  color: var(--dark-green);
  margin-bottom: 8px;
  font-family: 'Lora', serif;
}

.intro-box p {
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-green);
}

.intro-box p:first-of-type {
  font-weight: bold;
}

.intro-box p:last-of-type {
  font-style: italic;
}


.workplace-box {
  flex: 1;
  /* Takes up 1/4 of the screen width */
  max-width: 40%;
  background-color: var(--primary-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 10px;
  justify-content: center;
  min-width: 360px;
  min-height: 490px;
  padding: 10px;

}

/* Container for workplace name and rating */
.workplace-info {
  text-align: center;
  /* Pushes this content to the top */
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&display=swap');


.workplace-info h3 {
  /*font-family: 'Playfair Display', serif;*/
  font-family: 'Lora', serif;

  font-size: 2rem;
  margin-bottom: 0.2rem;
  color: var(--dark-green);
}

.workplace-info p {
  margin: 0.2rem 0;
  color: var(--dark-green);
}

.review-box-container {
  flex: 1;
  /* Allows it to grow to fill the remaining space */
  display: flex;
  align-items: center;
  /* Vertically centers the review box */
  justify-content: center;
  /* Horizontally centers the review box */
}

.review-and-image-box {
  display: flex;
  flex-direction: column;
}

.rating-stars {
  font-size: 1.2rem;
  color: #e3d2b1;
  /* Color for the empty stars */

}

.star {
  margin-right: 5px;
}

.rating-stars .filled {
  color: var(--dark-green);
  /* Color for the filled stars */
}

.review-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--third-color);
  /* background-color: #a484557b; */
  /* White-grayish background */
  border-radius: 10px;
  padding: 1rem;
  flex: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  /* Smooth transition */
  width: 100%;
  /* Ensures it takes full width within the parent container */
  min-height: 280px;
  max-height: 280px;
  /* Fixed height for consistent design */
  /* Prevents content from overflowing */
  position: relative;
  box-sizing: border-box;
  /* For better positioning of children */
}

.review-box:hover {
  transform: scale(1.02);
  /* Slightly enlarges the box */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* Adds a more pronounced shadow */
}

.review-content {
  flex-grow: 1;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Space out content vertically */
  height: 100%;
  max-width: 290px;
  /* Ensure the review content takes the full height of the box */
}

.review-text {
  flex-grow: 1;
  /* Take up remaining space in the container */
  display: block;
  align-items: center;
  text-align: center;
  color: #333;
  padding-top: 0.5rem;
  max-width: 200px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.review-box button {
  margin-top: 0.5rem;
  background: none;
  border: none;
  color: var(--light-green);
  cursor: pointer;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  color: var(--primary-color) !important;
  padding: 0 1rem;
  z-index: 1;
  /* Ensure arrows are above the review content */
}

.arrow-button:hover {
  color: var(--light-green) !important;
}

.workplace-box h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000000;
}

.workplace-box p {
  margin: 0.5rem 0;
  color: #000000;
  font-size: 20px;
  font-family: 'Raleway', sans-serif
}

.rating-text {
  font-family: Arial, Helvetica, sans-serif !important;
}

.workplace-box h4 {
  margin: 1rem 0 0.5rem;
}

.workplace-box ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #000000;
}

.map-container {
  height: auto;
  width: 100%;
  border: 1px solid var(--dark-green);
  border-radius: 10px;
  min-height: 400px;
  flex: 3;
}

.star {
  font-size: 200%;
}

.add-review-container {
  margin-top: 1rem;
  text-align: center;
}

.add-review-button {
  background-color: var(--third-color);
  color: var(--dark-green);
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: 'Raleway', sans-serif;
}

.add-review-button:hover {
  background-color: var(--secondary-color);
  transform: scale(1.02);
}

.leaflet-popup-close-button {
  display: none;
  /* Hide the close button (optional) */
}

.leaflet-popup-tip-container {
  display: none;
  /* Hide the arrow (tip) */
}

.leaflet-marker-icon.selected-marker {
  transform: scale(1.5);
  /* Make the marker larger */
  filter: brightness(1.5);
  /* Highlight the marker */
  z-index: 1000;
  /* Ensure it appears above other markers */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's on top of everything else */
}

/* Modal Content */
.modal-content {
  background: var(--primary-color);
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1010;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content h2 {
  font-family: 'Lora', serif;
}

/* Textarea for Review */
.modal-content textarea {
  width: 100%;
  height: 100px;
  border: 1px solid var(--dark-green);
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  resize: none;
}

/* Modal Actions */
.modal-actions {
  margin-top: 18px;
  display: flex;
  justify-content: space-around;
}

.modal-actions button {
  background-color: #333333f4;
  color: white;
  border: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  width: 35%;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-actions button:hover {
  background-color: #303030ca;
}

.modal-actions button:last-child {
  background-color: #d9534f;
  /* Red for cancel */
}

.modal-actions button:last-child:hover {
  background-color: #c9302c;
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.rating-stars-picker {
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
  font-size: 2rem;
}

.rating-stars-picker .star {
  color: #e9d6af !important;
  /* Default color for unselected stars */
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.rating-stars-picker .star.filled {
  color: var(--dark-green) !important;
  /* Highlight color for selected stars */
}

.rating-stars-picker .star:hover {
  transform: scale(1.2);
  /* Slightly enlarge the star on hover */
}

.error-message {
  background-color: #ffe0e0;
  /* Light red background */
  color: #d9534f;
  /* Darker red text */
  border: 1px solid #f5c6cb;
  /* Slightly darker border */
  border-radius: 5px;
  padding: 10px 15px;
  margin: 10px 0;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.error-message.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.add-workplace-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  /* Ensure it overlays the map */
  background-color: var(--third-color);
  /* Green background */
  color: var(--dark-green);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.add-workplace-button:hover {
  background-color: var(--secondary-color);
  /* Darker green */
}

/* Overlay to darken background */
.new-workplace-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.new-workplace-modal {
  background-color: var(--primary-color);
  padding: 1rem;
  border-radius: 8px;
  width: 420px;
  max-width: 90%;
  max-height: 98%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1010;
  overflow-y: auto;
  /* Enable vertical scrolling */
  text-align: center;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #888 #f0f0f0;
  /* Custom colors for Firefox */
}


/* Form title */
.new-workplace-modal h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Lora', serif;
}

/* Form groups */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  text-align: left;
}

/* Labels */
.form-group label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  font-size: 1rem;
  box-sizing: border-box;
}

/* Input fields */
.form-group input,
.form-group select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Buttons container */
.new-workplace-modal button {
  width: 48%;
  padding: 0.6rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0.5rem 1%;
  margin-top: 18px;
}

/* Submit button */
.new-workplace-modal button[type="submit"] {
  background-color: #333333f4;
  color: #fff;
  transition: background-color 0.3s ease;
}

.new-workplace-modal button[type="submit"]:hover {
  background-color: #303030ca;
}

/* Hide default file input */
.file-input {
  display: none;
}

/* Style the label to look like the submit button */
.file-upload-button {
  display: inline-block;
  width: 48%;
  padding: 6px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #333333f4;
  color: #fff;
  text-align: center;
  transition: background-color 0.3s ease;
}

.file-upload-label {
  font-weight: 500 !important;
  margin-top: 6px;
  margin-bottom: 8px;
  font-size: 16px !important;
  color: var(--dark-green) !important;
  text-align: center !important;
  font-family: 'Raleway', sans-serif !important;
}

.file-upload-button:hover {
  background-color: #303030ca;
}

/* Cancel button */
.new-workplace-modal button[type="button"] {
  background-color: #d9534f;
  color: #fff;
  transition: background-color 0.3s ease;
}

.new-workplace-modal button[type="button"]:hover {
  background-color: #c9302c;
}

.address-input {
  width: 100%;
}

.mapboxgl-ctrl-geocoder--suggestions {
  z-index: 9999;
  /* Ensure suggestions stay on top */
  pointer-events: auto;
  /* Ensure mouse events are captured */
}

.address-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.suggestions-list {
  top: 100%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  /* Limit the height */
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Styling each suggestion item */
.suggestion-item {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}

/* Highlight the selected suggestion (if needed) */
.suggestion-item.selected {
  background-color: #007bff;
  color: white;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* Modal overlay */
.reviews-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal content */
.reviews-modal-content {
  background: var(--primary-color);
  border-radius: 8px;
  width: 80%;
  max-width: 700px;
  max-height: 98%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: auto;
}

.reviews-modal-content h2 {
  font-family: 'Lora', serif;
  font-size: 28px;
}

.reviews-modal-content p {
  font-size: 18px;
}

/* Close button */
.reviews-modal-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* Reviews list */
.reviews-list {
  list-style-type: none !important;
  padding: 0;
  margin: 0;
}

.review-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  font-size: 16px;
}

.review-item:last-child {
  border-bottom: none;
}

@media (max-width: 576px) {
  .home {
    min-width: none;
  }

  .review-box:hover {
    transform: none;
    /* Remove the scale effect */
    box-shadow: none;
    /* Remove the shadow */
  }

  .content-container {
    flex-direction: column;
  }

  .filters-box {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  .workplace-box {
    min-width: 100%;
    min-height: 0px;
  }

  .filters-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    min-width: 85%;
  }

  .apply-filters-button {
    margin-bottom: 5px;
    min-width: 50%;
  }

  .clear-filters-button {
    min-width: 50%;
    margin-left: 0;
  }

  .workplace-filters-container {
    margin: 0;
  }

  .filters-group label {
    margin-right: 0;
    margin-bottom: 6px;
  }

  select {
    background-color: white;
    color: black;
  }

  .arrow-button:hover {
    color: none !important;
  }
}