@import '../colors.css';

.footer {
    background: var(--secondary-color);
}

.footer-content {
    padding: 7px;
}

.footer-content p {
    color: var(--dark-green);
    font-weight: 600;
}