@import '../colors.css';


/* Center the button inside its container */
.view-images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Ensures it takes full width of the parent */
    height: 100%;
    /* Ensures it takes full height of the parent */
}

.image-overlay-content h3 {
    font-family: 'Lora', serif;
    font-size: 24px;
    margin-bottom: 0.2rem;
    color: var(--dark-green);
}

/* View Images Button */
.view-images-btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background: var(--third-color);
    color: var(--dark-green);
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top: 10px;
    position: relative;
    max-width: 150px;
    border-radius: 5px;
    font-family: 'Raleway', sans-serif;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.view-images-btn:hover {
    background-color: var(--secondary-color);
    transform: scale(1.02);
}

/* Fullscreen Overlay */
.image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    /* Dark semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Overlay Content */
.image-overlay-content {
    position: relative;
    padding-top: 15px;
    padding-bottom: 4px;
    margin-left: 3px;
    margin-right: 3px;
    min-width: 380px;
    min-height: 300px;
    background-color: var(--primary-color);
    border-radius: 10px;
    max-height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: fit-content;
}

.image-carousel-container {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 390px;
}

.image-overlay-content p {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    margin: 6px;
    color: var(--dark-green);
    text-align: center;
    margin-top: 25%;
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 40px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: white;
    z-index: 9999;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: background 0.3s ease-in-out;
    padding: 1px 7px;
}

.close-btn:hover {
    background: rgba(0, 0, 0, 0.7);
}

.no-scroll {
    overflow: hidden;
    height: 100vh;
}